<template>
  <tr>
    <td>
      <v-icon :style="`color: ${employee.color}`" class="mr-3">
        {{ employee.color ? "brightness_1" : "panorama_fish_eye" }}
      </v-icon>
      {{ employee.fullName }}
    </td>
    <td>{{ user.email }}</td>
    <td>
      <v-chip v-for="(role, index) in maxRoles" :key="index" label small>
        {{ role.name }}
      </v-chip>
      <v-tooltip v-if="roles.length > 5" bottom>
        <v-chip slot="activator" v-html="'...'" />
        <span>
          <v-chip v-for="(role, index) in roles" :key="index" label>
            {{ role.name }}
          </v-chip>
        </span>
      </v-tooltip>
    </td>
    <td>
      <v-btn
        icon
        :to="{ name: 'employees.show', params: { employeeId: employee.id } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const {
  mapGetters: mapEmployeeGetters,
  mapState: mapEmployeeState
} = createNamespacedHelpers("employees");

export default {
  name: "employee-table-row",
  props: {
    employeeId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapEmployeeGetters([FIND_BY_ID]),
    ...mapEmployeeState(["role"]),
    employee() {
      return this[FIND_BY_ID](this.employeeId);
    },
    user() {
      return this.$store.getters["employees/findUser"](this.employee.user);
    },
    roles: function() {
      let employeeRoles = this.user.roles.map(roleId => this.role[roleId]);
      employeeRoles.filter(function(role) {
        return role !== undefined;
      });
      return employeeRoles;
    },
    maxRoles: function() {
      let employeeRoles = this.user.roles.map(roleId => this.role[roleId]);
      let filtered = employeeRoles.filter(function(role) {
        return role !== undefined;
      });
      filtered.slice(0, 5);
      return filtered;
    }
  }
};
</script>
