<template>
  <table-overview
    title="Employees"
    store="employees"
    :headers="[
      { text: 'Name', value: 'first_name' },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Roles', value: 'roles', sortable: false },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'employees_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <employee-table-row
      slot="table-row"
      slot-scope="{ item }"
      :employee-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import employeesModule from "@/store/modules/employees";
import EmployeeTableRow from "../../components/employee/EmployeeTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "employees",
  employeesModule
);

export default {
  name: "employees-view",
  components: {
    EmployeeTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "employees.create" },
        rolesOrPermissions: "employees_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
