<template>
  <v-card color="white">
    <crud-header
      :title="fullName"
      type="Employee"
      store="employee"
      @save-success="
        ({ id }) =>
          $router.push({ name: 'employees.show', params: { employeeId: id } })
      "
    />
    <employee />
  </v-card>
</template>

<script type="text/babel">
import employeeModule from "@/store/modules/employee";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "@/views/helpers";
import Employee from "@/components/employee/Employee";

const { mapGetters } = createNamespacedHelpers("employee");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "employee",
  employeeModule
);

export default {
  name: "employee-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Employee,
    CrudHeader
  },
  computed: {
    ...mapGetters(["fullName"])
  },
  ...createViewLifecycleMethods
};
</script>
