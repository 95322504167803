<template>
  <v-container fluid>
    <v-layout row v-if="preview">
      <v-flex xs12 class="text-xs-center">
        <img
          v-bind:src="imgSrc"
          :style="{
            'max-width': maxSize,
            'max-height': maxSize
          }"
        />
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-text-field
          v-model="fileName"
          :label="label"
          :placeholder="placeholder"
          prepend-icon="far fa-cloud-upload"
          readonly
          :error-messages="errorMessages"
          @click:prepend="$refs.file.click()"
          @click="$refs.file.click()"
        />
        <input
          style="display: none;"
          type="file"
          id="file"
          ref="file"
          accept="image/*"
          v-on:change="handleFileUpload()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "v-upload-image",
  props: {
    value: {
      type: [Array, String, File],
      default: undefined
    },
    label: {
      type: String,
      default: () => "Upload image"
    },
    placeholder: {
      type: String,
      default: () => "Click to upload"
    },
    preview: {
      type: Boolean,
      default: false
    },
    default: {
      type: String,
      default: undefined
    },
    maxSize: {
      type: String,
      default: "400px"
    },
    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  data: () => ({
    reader: undefined,
    fileName: null,
    image: null
  }),
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue === "string") {
          this.image = newValue;
        } else if (newValue instanceof File) {
          // Fire the readAsDataURL method which will read the file in and upon completion fire a 'load' event which we will listen to and display the image in the preview.
          this.reader.readAsDataURL(newValue);
          // Set the fileName to be used in the fake text field.
          this.fileName = newValue.name;
        }
      },
      immediate: true
    }
  },
  created() {
    // Initialize a File Reader object
    this.reader = new FileReader();
    // Add an event listener to the reader that when the file has been loaded, we flag the show preview as true and set the image to be what was read from the reader.
    this.reader.addEventListener("load", this.setPreview, false);
  },
  computed: {
    imgSrc() {
      if (!isNil(this.image)) {
        return this.image;
      }
      if (!isNil(this.default)) {
        return this.default;
      }
      return `https://via.placeholder.com/${this.maxSize}`;
    }
  },
  methods: {
    // Handles a change on the file upload
    handleFileUpload() {
      // Set the local file variable to what the user has selected.
      const file = this.$refs.file.files[0];
      // Check to see if the file is not empty and ensure the file is an image file
      if (file && /\.(jpe?g|png|gif)$/i.test(file.name)) {
        // Pass the value up to the parent's v-model
        this.$emit("input", file);
      }
    },
    setPreview(event) {
      this.image = event.currentTarget.result;
    }
  }
};
</script>
<style scoped>
div.image-container img {
  max-width: 200px;
  max-height: 200px;
}
</style>
