<template>
  <v-card color="white">
    <crud-header
      :title="fullName"
      type="Employee"
      store="employee"
      :deletable="hasRolesOrPermissions('employee_delete')"
      @delete-success="$router.push({ name: 'employees' })"
    >
      <template slot="toolbarActions">
        <v-btn icon @click="openResetPassDialog">
          <v-icon>far fa-fw fa-key</v-icon>
        </v-btn>
      </template>
    </crud-header>
    <employee />
    <v-dialog v-model="isResetPasswordDialogOpen" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Reset password?
        </v-card-title>
        <v-card-text
          >Are you sure you want to reset {{ fullName }}'s password? The user
          will receive an email containing a new random generated password.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!isResettingPassword"
            flat
            @click.native="isResetPasswordDialogOpen = false"
          >
            Nevermind
          </v-btn>
          <v-btn
            v-if="!isResettingPassword"
            color="primary"
            flat
            @click.native="resetPassword"
          >
            I'm sure
          </v-btn>
          <v-progress-circular v-if="isResettingPassword" indeterminate />
          <v-spacer v-if="isResettingPassword" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script type="text/babel">
import employeeModule from "@/store/modules/employee";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "@/views/helpers";
import Employee from "@/components/employee/Employee";
import api from "@/api";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters } = createNamespacedHelpers("employee");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "employee",
  employeeModule
);

export default {
  name: "employee-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Employee,
    CrudHeader
  },
  data: () => ({
    resetButton: {
      icon: "far fa-fw fa-key"
    },
    isResetPasswordDialogOpen: false,
    isResettingPassword: false
  }),
  metaInfo() {
    return {
      title: `Employee - ${this.fullName}`
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapGetters(["fullName", "id"])
  },
  methods: {
    openResetPassDialog() {
      this.isResetPasswordDialogOpen = true;
    },
    async resetPassword() {
      try {
        this.isResettingPassword = true;
        await api.employee.resetPassword(this.id);
        this.$store.dispatch("snackbar/addItem", {
          text: `${this.fullName}'s password has been reset`,
          color: "success"
        });
        this.isResettingPassword = false;
        this.isResetPasswordDialogOpen = false;
      } catch (error) {
        console.error(error);
        this.$store.dispatch("snackbar/addItem", {
          text: `${this.fullName}'s password failed to reset`,
          color: "error"
        });
      }
    }
  },
  ...detailViewLifecycleMethods
};
</script>
