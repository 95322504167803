<script type="text/babel">
import {
  capitalize,
  cloneDeep,
  debounce,
  isArray,
  isEmpty,
  isNil,
  replace,
  snakeCase,
  uniq
} from "lodash";
import {
  IS_ASYNC,
  IS_INITIALIZED,
  IS_LOADING,
  ITEMS
} from "@/store/templates/select/getter-types";
import { INIT, SEARCH } from "@/store/templates/select/action-types";
import { SET_ASYNC_CACHE } from "@/store/templates/select/mutation-types";

export default {
  inject: ["$validator"],
  props: {
    value: {
      type: [Number, Array, Object],
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: undefined
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemValue: {
      type: String,
      default: "id"
    },
    itemText: {
      type: String,
      default: "name"
    },
    dataVvRules: {
      type: String,
      default: undefined
    },
    dataVvName: {
      type: String,
      default: undefined
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    itemFilterFn: {
      type: Function,
      default: undefined
    }
  },
  data: () => ({
    search: null,
    getter: null,
    asyncFilters: {},
    initFilters: {},
    includes: [],
    test: null
  }),
  created() {
    this.init();
  },
  watch: {
    value: "onChangeValue",
    items() {
      if (this.empty || !this.isInitialized) {
        // Only run handler when there is a value and the vuex module is initialized
        return;
      }
      const itemIds = this.items.map(item => item[this.itemValue]);
      if (this.isMultiple) {
        const foundValues = this.model.filter(singleValue =>
          itemIds.includes(singleValue)
        );
        if (this.model.length !== foundValues.length) {
          this.$emit("input", foundValues);
        }
      } else {
        if (!itemIds.includes(this.model)) {
          this.$emit("input", null);
        }
      }
    },
    search(newValue) {
      if (this.isAsync) {
        this.debouncedSearch(newValue, this.doSearch);
      }
    },
    allFilters: {
      deep: true,
      handler() {
        const filters = cloneDeep(this.allFilters);
        if (this.isStoreRegistered) {
          this.$store.dispatch(`${this.store}/${INIT}`, {
            filters,
            includes: this.includes
          });
        }
      }
    }
  },
  computed: {
    allFilters() {
      return { ...this.asyncFilters, ...this.initFilters };
    },
    model: {
      get() {
        if (this.isMultiple && isNil(this.value)) {
          return [];
        }
        return this.value;
      },
      set(newValue) {
        if (isNil(newValue)) {
          this.$emit("input", null);
        }
        if (this.multiple && !isArray(newValue)) {
          // If is multiple, but newValue is not an array we wrap it in one.
          this.$emit("input", [newValue]);
        }
        this.$emit("input", newValue);
      }
    },
    isStoreRegistered() {
      return !isNil(this.$store.state[this.store]);
    },
    items() {
      if (!this.isStoreRegistered) {
        return [];
      }
      const items = this.$store.getters[
        `${this.store}/${this.getter ? this.getter : ITEMS}`
      ];
      if (!isNil(this.itemFilterFn)) {
        return items.filter(this.itemFilterFn);
      }
      if (this.isAsync) {
        let selectedItems = [];
        if (!isNil(this.value)) {
          const value = this.isMultiple ? this.value : [this.value];
          selectedItems = value
            // Map the ids to actual entities
            .map(id => this.$store.state[this.store].entities.entity[id])
            // Filter out any undefined entries from the array
            .filter(item => !isNil(item));
        }

        return uniq(selectedItems.concat(items));
      }
      return items;
    },
    internalErrorMessages() {
      return this.errorMessages.concat(this.errors.collect(this.internalName));
    },
    internalValidationRules() {
      let validationRules = [];
      // Add validation rules based on generic props
      if (this.required) {
        validationRules.push("required");
      }
      // Supplement validation rules with specific ones.
      if (!isNil(this.dataVvRules)) {
        this.dataVvRules.split("|").forEach(singleRule => {
          // If the rule has a colon, it's a rule with properties, so we need to override any properties
          if (singleRule.indexOf(":") > -1) {
            const ruleName = singleRule.substr(0, singleRule.indexOf(":"));
            // If the rule name matches override it, otherwise leave as it is.
            validationRules = validationRules.map(rule =>
              rule.startsWith(`${ruleName}:`) ? singleRule : rule
            );
          } else {
            if (!validationRules.includes(singleRule)) {
              validationRules.push(singleRule);
            }
          }
        });
      }

      // Either return a | separated string from the validationRules array, of undefined if no rules
      return validationRules.join("|");
    },
    internalName() {
      if (!isNil(this.name)) {
        return this.name;
      }
      if (!isNil(this.internalLabel)) {
        return snakeCase(this.internalLabel);
      }
      return undefined;
    },
    isInitialized() {
      return (
        this.isStoreRegistered &&
        this.$store.getters[`${this.store}/${IS_INITIALIZED}`]
      );
    },
    isLoading() {
      return (
        this.isStoreRegistered &&
        (this.loading || this.$store.getters[`${this.store}/${IS_LOADING}`])
      );
    },
    isAsync() {
      return (
        this.isStoreRegistered &&
        this.$store.getters[`${this.store}/${IS_ASYNC}`]
      );
    },
    isDisabled() {
      return this.disabled || this.isLoading;
    },
    isReadOnly() {
      return this.disabled || this.isLoading;
    },
    isMultiple() {
      return this.multiple;
    },
    empty() {
      if (this.isMultiple) {
        return this.model.length === 0;
      }
      return isNil(this.model);
    },
    internalLabel() {
      let label = "no label";

      if (!isNil(this.defaultLabel)) {
        label = this.isMultiple
          ? !isNil(this.defaultLabelMultiple)
            ? this.defaultLabelMultiple
            : `${this.defaultLabel}s`
          : this.defaultLabel;
      }

      if (!isNil(this.label)) {
        label = this.label;
      }

      return capitalize(label);
    },
    browserAutocomplete() {
      if (navigator.platform.indexOf("Mac") > -1) {
        return "off";
      }
      const label = this.internalLabel.toLowerCase().replace(" ", "-");
      return `disable-browser-autocomplete-${label}`;
    }
  },
  methods: {
    debouncedSearch: debounce((searchValue, searchMethod) => {
      searchMethod(searchValue);
    }, 500),
    doSearch(search) {
      const filters = cloneDeep(this.asyncFilters);
      filters.search = search;
      this.$store.dispatch(`${this.store}/${SEARCH}`, {
        filters,
        includes: this.includes
      });
    },
    async init() {
      await this.initStore();
      await this.initData();
    },
    async initStore() {
      if (isNil(this.store)) {
        throw Error("No store name defined.");
      }
      if (isNil(this.$store.state[this.store])) {
        const module = await import(`@/store/modules/${this.store}`);
        if (isNil(this.$store.state[this.store])) {
          // Double check here, because of the race condition introduced by the async import.
          this.$store.registerModule(this.store, module.default);
          console.debug(`[VUEX] Registering ${this.store} module.`);
        }
      }
    },
    async initData() {
      let initialIds = [];
      if (!isNil(this.value)) {
        initialIds = (this.isMultiple ? this.value : [this.value]).filter(
          id => !isNil(id)
        );
      }
      if (initialIds.length > 0) {
        const filters = { ids: initialIds, ...cloneDeep(this.initFilters) };
        await this.$store.dispatch(`${this.store}/${INIT}`, {
          filters,
          includes: this.includes
        });
      } else {
        const filters = cloneDeep(this.asyncFilters);
        this.$store.dispatch(`${this.store}/${INIT}`, {
          filters,
          includes: this.includes
        });
      }
    },
    onChange() {
      this.search = null;
    },
    onChangeValue() {
      this.cacheAsyncSelection();
      this.revalidate();
    },
    cacheAsyncSelection() {
      if (this.isAsync) {
        const ids = isArray(this.value) ? this.value : [this.value];
        const currentSelection = ids
          .filter(id => !isNil(id))
          .map(id => this.items.find(item => item.id === id));

        this.$store.commit(
          `${this.store}/${SET_ASYNC_CACHE}`,
          currentSelection
        );
      }
    },
    revalidate() {
      if (
        !isNil(this.$validator) &&
        !isNil(this.internalName) &&
        !isEmpty(this.internalValidationRules)
      ) {
        this.$validator.validate(this.internalName);
      }
    },
    addSearchHighlightMarkup(text) {
      if (isNil(text) || isNil(this.search)) {
        return text;
      }
      return replace(
        text,
        new RegExp(this.search, "i"),
        capture => `<span class="v-list__tile__mask">${capture}</span>`
      );
    }
  }
};
</script>
