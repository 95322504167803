<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs7>
        <v-text-field
          v-model="firstName"
          v-validate="'required|max:255'"
          label="First name"
          autocomplete="not-my-first-name"
          :counter="255"
          :error-messages="errors.collect('firstName')"
          data-vv-name="firstName"
          required
        />
        <v-text-field
          v-model="lastName"
          v-validate="'required|max:255'"
          label="Last name"
          autocomplete="not-my-last-name"
          :counter="255"
          :error-messages="errors.collect('lastName')"
          data-vv-name="lastName"
          required
        />
        <v-text-field
          v-model="email"
          v-validate="'required|email|max:255'"
          label="E-mail"
          autocomplete="not-my-email"
          :counter="255"
          :error-messages="errors.collect('user.email')"
          data-vv-name="email"
          required
        />
        <v-autocomplete-role v-model="roles" multiple />
        <v-autocomplete-permission v-model="permissions" multiple />
        <v-text-field v-model="color" type="color" label="Color" />
      </v-flex>
      <v-flex xs5>
        <v-upload-image
          v-model="picture"
          preview
          label="Profile picture"
          placeholder="Click to upload a new profile picture"
          :default="defaultAvatar"
          :error-messages="errors.collect('picture')"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteRole from "@/components/role/VAutocompleteRole";
import VAutocompletePermission from "@/components/permission/VAutocompletePermission";
import VUploadImage from "@/components/generic/input/VUploadImage";
import defaultAvatar from "../../assets/avatar.png";

const { mapComputed } = createNamespacedHelpers("employee");

export default {
  name: "employee",
  components: { VUploadImage, VAutocompleteRole, VAutocompletePermission },
  inject: ["$validator"],
  data: () => ({
    image: undefined,
    defaultAvatar: defaultAvatar
  }),
  computed: {
    ...mapComputed([
      "firstName",
      "lastName",
      "email",
      "roles",
      "permissions",
      "color",
      "picture"
    ])
  }
};
</script>
